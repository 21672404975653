import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import MenuItem from "./menu-item/MenuItem"
import * as styles from "./styles"
import PropTypes from "prop-types"

const MenuAdditional = ({ header }) => {
    const data = useStaticQuery(
        graphql`
            query {
                wpMenu(locations: { eq: MENU_ADDITIONAL }) {
                    menuItems {
                        nodes {
                            id
                            label
                            url
                            title
                            target
                            linkRelationship
                        }
                    }
                }
                wp {
                    generalSettings {
                        url
                    }
                }
            }
        `
    )

    const {
        menuItems: { nodes: menuItems },
    } = data.wpMenu
    const {
        generalSettings: { url: generalSettings },
    } = data.wp

    return (
        <>
            <h5 dangerouslySetInnerHTML={{ __html: header }} />
            <ul className={styles.Menu}>
                {menuItems?.map(menuItem => (
                    <MenuItem
                        key={menuItem.id}
                        menuItem={menuItem}
                        wordPressUrl={generalSettings}
                        elementClick="footer-additional-menu"
                    />
                ))}
            </ul>
        </>
    )
}

MenuAdditional.propTypes = {
    header: PropTypes.string.isRequired,
}

export default MenuAdditional

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import * as styles from "./styles"
import dataAttributes from "../../../../utils/dataAttributes"
import PropTypes from "prop-types"
import Icon from "../../../ui/icons/Icon"

const MenuSocialMedia = ({ header }) => {
    const data = useStaticQuery(
        graphql`
            query {
                wp {
                    socialmedia {
                        acf {
                            socialmedia {
                                icon
                                link
                                name
                            }
                        }
                    }
                }
            }
        `
    )

    if (data.wp.socialmedia.acf.socialmedia) {
        const socialMediaItems = data.wp.socialmedia.acf.socialmedia

        return (
            <>
                <h5 dangerouslySetInnerHTML={{ __html: header }} />
                <ul className={styles.Menu + " " + styles.SocialMedia}>
                    {socialMediaItems &&
                        socialMediaItems.map((socialMediaItem, index) => (
                            <li
                                key={index}
                                className={styles.SocialMedia__Item}
                            >
                                <a
                                    className={styles.SocialMedia__Link}
                                    href={socialMediaItem.link}
                                    target="_blank"
                                    rel="noreferrer noopener nofollow"
                                    {...dataAttributes(
                                        "footer-social",
                                        socialMediaItem.name
                                    )}
                                >
                                    <div className={styles.SocialMedia__Icon}>
                                        <Icon icon={socialMediaItem.icon} />
                                    </div>
                                    <div className={styles.SocialMedia__Label}>
                                        {socialMediaItem.name}
                                    </div>
                                </a>
                            </li>
                        ))}
                </ul>
            </>
        )
    } else {
        return null
    }
}

MenuSocialMedia.propTypes = {
    header: PropTypes.string.isRequired,
}

export default MenuSocialMedia

import { useEffect, useState } from "react"
import { isBrowser } from "../utils/isBrowser"

const useOnScreen = ref => {
    const [isIntersecting, setIntersecting] = useState(false)

    useEffect(() => {
        const observer =
            isBrowser() &&
            new IntersectionObserver(([entry]) =>
                setIntersecting(entry.isIntersecting)
            )
        isBrowser() && observer.observe(ref.current)
        return () => {
            isBrowser() && observer.disconnect()
        }
    }, [ref])

    return isIntersecting
}
export default useOnScreen

import { useEffect } from "react"
import { isBrowser } from "../utils/isBrowser"

const useScrollToHash = ref => {
    useEffect(() => {
        if (!isBrowser()) return
        if (window.location.hash) {
            const element = document.getElementById(
                window.location.hash.substring(1)
            )
            if (!element) return

            //adjust time for conference slider height
            setTimeout(() => {
                var offset = 65
                var elementPosition = element.getBoundingClientRect().top
                var offsetPosition =
                    elementPosition + window.pageYOffset - offset

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                })
            }, 2000)
        }
    }, [])
}
export default useScrollToHash

/**
 * Parses a menu item object and returns Gatsby-field URI.
 *
 * @param {string} menuItemUrl
 * @param {string} wordPressUrl
 */
export const createLocalLink = (menuItemUrl, wordPressUrl) => {
    if (menuItemUrl === "#") {
        return null
    }
    /**
     * Always want to pull of our API URL.
     */

    return menuItemUrl.replace(wordPressUrl, "")
}

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Email from "./email/Email"

const ContactData = ({ email }) => {
    const data = useStaticQuery(
        graphql`
            query {
                wp {
                    footer {
                        acf {
                            footerEmailHeader
                            footerEmail
                            footerEmailCopy
                            footerEmailCopied
                        }
                    }
                }
            }
        `
    )

    if (data.wp.footer.acf) {
        const {
            footerEmailHeader,
            footerEmail,
            footerEmailCopy,
            footerEmailCopied,
        } = data.wp.footer.acf

        return (
            <Email
                header={footerEmailHeader}
                email={email ?? footerEmail}
                emailCopy={footerEmailCopy}
                emailCopied={footerEmailCopied}
            />
        )
    } else {
        return null
    }
}

export default ContactData

import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"

import { generateTitleMeta, isBrowser } from "../../../utils"
import { Html5Entities } from "html-entities"

import favicon from "../../../../static/favicon.ico"
import favicon16 from "../../../../static/favicon-16x16.png"
import favicon32 from "../../../../static/favicon-32x32.png"

function Seo({ children, description, lang, title, ogImage }) {
    const entities = new Html5Entities()
    const host = isBrowser() ? window.origin : ""

    const data = useStaticQuery(
        graphql`
            query {
                wp {
                    general {
                        acf {
                            tagline
                        }
                    }
                }
            }
        `
    )
    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={entities.decode(
                generateTitleMeta(title, data.wp.general.acf.tagline)
            )}
            meta={[
                {
                    name: "description",
                    content: description,
                },
                {
                    property: "og:image",
                    content: `${host}${ogImage}`,
                },
                {
                    property: "og:title",
                    content: title,
                },
                {
                    property: "og:url",
                    content: isBrowser() ? window.location.href : "",
                },
                {
                    name: "twitter:image",
                    content: `${host}${ogImage}`,
                },
            ]}
            link={[
                {
                    rel: "icon",
                    type: "image/png",
                    sizes: "16x16",
                    href: `${favicon}`,
                },
                {
                    rel: "icon",
                    type: "image/png",
                    sizes: "32x32",
                    href: `${favicon16}`,
                },
                {
                    rel: "icon",
                    type: "image/png",
                    href: `${favicon32}`,
                },
                {
                    rel: "canonical",
                    href: isBrowser() ? window.location.href : "",
                },
            ]}
        >
            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "url": "https://future-processing.com",
                        "name": "Future processing",
                        "sameAs": [
                            "https://www.facebook.com/FutureProcessing",
                            "https://twitter.com/futureprocessin",
                            "https://www.linkedin.com/company/future-processing/?originalSubdomain=pl",
                            "https://www.instagram.com/futureprocessing/?hl=pl"
                        ],
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "email": "mgrela@future-processing.com",
                            "contactType": "Customer Support"
                        },
                        "address": {
                            "@type": "PostalAddress",
                            "addressLocality": "Gliwice",
                            "streetAddress": "Bojkowska 37a",
                            "addressCountry": "Poland"
                        }
                    }
                `}
            </script>
            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "WebSite",
                        "url": "https://itinsights.tech"
                    }
                `}
            </script>
            {children}
        </Helmet>
    )
}

Seo.propTypes = {
    children: PropTypes.any,
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
}

export default Seo

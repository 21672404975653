import React from "react"
import { createLocalLink } from "../../../../../utils/createLocalLink"
import { Link } from "gatsby"
import * as styles from "./styles"
import dataAttributes from "../../../../../utils/dataAttributes"

const MenuItem = ({
    menuItem,
    wordPressUrl,
    elementClick = "data-element-click",
}) => {
    if (menuItem.connectedObject) {
        return (
            <li className={styles.MenuItem}>
                <Link
                    {...dataAttributes(elementClick, menuItem.label)}
                    to={createLocalLink(menuItem.url, wordPressUrl)}
                    dangerouslySetInnerHTML={{ __html: menuItem.label }}
                    target={menuItem.target}
                    rel={menuItem.linkRelationship}
                />
            </li>
        )
    } else {
        return (
            <li className={styles.MenuItem}>
                <a
                    {...dataAttributes(elementClick, menuItem.label)}
                    href={menuItem.url}
                    dangerouslySetInnerHTML={{ __html: menuItem.label }}
                    target={menuItem.target}
                    rel={menuItem.linkRelationship}
                />
            </li>
        )
    }
}

export default MenuItem

// extracted by mini-css-extract-plugin
export var Button = "Button-module--Button--3kQE_";
export var ButtonIsIconRight = "Button-module--Button--IsIconRight--2C9py";
export var Label = "Button-module--Label--3JWMm";
export var ButtonIsIconLeft = "Button-module--Button--IsIconLeft--3E8wO";
export var ButtonIsLarge = "Button-module--Button--IsLarge--3LyVq";
export var ButtonIsSmall = "Button-module--Button--IsSmall--nIgGR";
export var ButtonIsPlay = "Button-module--Button--IsPlay--3Yzzm";
export var ButtonIsXSmall = "Button-module--Button--IsXSmall--3AjVu";
export var ButtonIsIcon = "Button-module--Button--IsIcon--11qVA";
export var IconRight = "Button-module--IconRight--X9p-A";
export var IconLeft = "Button-module--IconLeft--2kiGP";
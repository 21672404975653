import React, { useState, useRef } from "react"
import * as styles from "./styles"
import dataAttributes from "../../../../utils/dataAttributes"
import PropTypes from "prop-types"
import classnames from "classnames"

const Email = ({ header, email, emailCopy, emailCopied }) => {
    const [isCopied, setIsCopied] = useState(false)
    const textAreaRef = useRef(null)

    const copyEmail = () => {
        textAreaRef.current.select()
        document.execCommand("copy")
        setIsCopied(true)

        setTimeout(() => {
            setIsCopied(false)
        }, 3000)
    }

    return (
        <>
            <h5>{header}</h5>
            <textarea
                className="u-Hide"
                ref={textAreaRef}
                defaultValue={email}
            />
            <a
                {...dataAttributes("footer-email-click", "Email - click")}
                className={styles.Email}
                href={`mailto:${email}`}
                dangerouslySetInnerHTML={{ __html: email }}
            />
            <button
                {...dataAttributes(
                    "footer-email-copy",
                    "Email - copy to clipboard"
                )}
                className={classnames(styles.Copy, "o-Link", {
                    [styles.CopyIsCopied]: isCopied,
                })}
                onClick={copyEmail}
            >
                <span className={styles.Copy__Copy}>{emailCopy}</span>
                <span className={styles.Copy__Copied}>{emailCopied}</span>
            </button>
        </>
    )
}

Email.propTypes = {
    header: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    emailCopy: PropTypes.string.isRequired,
    emailCopied: PropTypes.string.isRequired,
}

export default Email

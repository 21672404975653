import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { TranslationContextProvider } from "../../context/TranslationContext"
import Footer from "../layout/footer/Footer"
import useScrollToHash from "../../hooks/useScrollToHash"

const Layout = ({ children, footerEmail }) => {
    const data = useStaticQuery(
        graphql`
            query {
                ...translations
            }
        `
    )

    useScrollToHash()

    return (
        <TranslationContextProvider translations={data.wp.phrases.acf}>
            <main>{children}</main>
            <Footer email={footerEmail} />
        </TranslationContextProvider>
    )
}

export default Layout

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import MenuItem from "./menu-item/MenuItem"

const MenuFooter = () => {
    const data = useStaticQuery(
        graphql`
            query {
                wpMenu(locations: { eq: MENU_FOOTER }) {
                    menuItems {
                        nodes {
                            id
                            label
                            url
                            title
                            target
                            linkRelationship
                        }
                    }
                }
                wp {
                    generalSettings {
                        url
                    }
                }
            }
        `
    )

    const {
        menuItems: { nodes: menuItems },
    } = data.wpMenu

    const {
        generalSettings: { url: generalSettings },
    } = data.wp

    return (
        <ul>
            {menuItems?.map(menuItem => (
                <MenuItem
                    key={menuItem.id}
                    menuItem={menuItem}
                    wordPressUrl={generalSettings}
                    elementClick="footer-bottom-menu"
                />
            ))}
            <li><a 
                dangerouslySetInnerHTML={{ __html: "Cookies" }}
                type="button" 
                data-cc="show-preferencesModal"/>
            </li>
        </ul>
    )
}

export default MenuFooter

import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import MenuPrimary from "./menu/MenuPrimary"
import MenuAdditional from "./menu/MenuAdditional"
import ContactData from "./ContactData"
import MenuSocialMedia from "./menu/MenuSocialMedia"
import MenuFooter from "./menu/MenuFooter"
import * as styles from "./styles"
import logoFP from "./../../../images/fp-logo.svg"
import dataAttributes from "../../../utils/dataAttributes"

const Footer = ({ email }) => {
    const data = useStaticQuery(
        graphql`
            query {
                wp {
                    footer {
                        acf {
                            footerPrimaryMenuTitle
                            footerAdditionalMenuTitle
                            footerSocialMediaTitle
                            footerCopyrights
                            footerLogoText
                        }
                    }
                }
            }
        `
    )

    const {
        footerPrimaryMenuTitle,
        footerAdditionalMenuTitle,
        footerSocialMediaTitle,
        footerCopyrights,
        footerLogoText,
    } = data.wp.footer.acf

    return (
        <footer>
            <div className={styles.Footer__Menus}>
                <div className="row l-Inner">
                    <div className={"column small-6 medium-4 large-2"}>
                        <MenuPrimary header={footerPrimaryMenuTitle} />
                    </div>
                    <div className="column small-6 medium-4 large-2">
                        <MenuAdditional header={footerAdditionalMenuTitle} />
                    </div>
                    <div className="column small-12 medium-4 large-2">
                        <MenuSocialMedia header={footerSocialMediaTitle} />
                    </div>
                    <div className="column small-12 large-offset-2 large-4">
                        <ContactData email={email} />
                    </div>
                </div>
            </div>
            <div className={styles.Footer__Bottom + " row l-Inner"}>
                <div className={"column small-12 medium-4"}>
                    {footerLogoText}
                    <a
                        {...dataAttributes("footer-fp-logo", "FP logo")}
                        href="https://future-processing.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            className={styles.Footer__Logo}
                            src={logoFP}
                            alt="Future Processing"
                        />
                    </a>
                </div>
                <div
                    className={
                        styles.Footer__Copyrights + " column small-12 medium-8"
                    }
                >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: footerCopyrights,
                        }}
                    />
                    <MenuFooter />
                </div>
            </div>
        </footer>
    )
}

export default Footer

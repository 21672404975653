import { isHomepage } from "./isHomepage"

/**
 * Generate content of <title> tag
 *
 * @param title {string}
 * @param tagline {string}
 * @returns {string}
 */
export const generateTitleMeta = (title, tagline) => {
    const homepageTitle = `Future Processing | ${tagline}`

    const pageTitle = `${title} | Future Processing`

    return isHomepage() ? homepageTitle : pageTitle
}

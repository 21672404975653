import React, { createContext } from "react"

export const TranslationContext = createContext({})

export const TranslationContextProvider = ({ children, translations }) => {
    return (
        <TranslationContext.Provider value={translations}>
            {children}
        </TranslationContext.Provider>
    )
}
